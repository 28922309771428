import "./styles.scss"

import React from "react"

import { useLocale } from "context/locale"

import LogoNoveSlichowice from "assets/images/logo-nove-slichowice.svg"
import LogoSkyTrust from "assets/images/logo-sky-trust.svg"
import LogoStacjaKopernik from "assets/images/logo-stacja-kopernik.svg"

const Data = ({ title, data }) => {
  const { t } = useLocale()

  return (
    <section className="apartment-data">
      <div className="container-fluid">
        <div className="apartment-data__header">
          <h1>
            {title ? title : data?.apartmentNumber} <span>{data.area}</span>
          </h1>
          {data.investment === "sky_trust" ? (
            <img src={LogoSkyTrust} alt="" />
          ) : data.investment === "sky_trust_offices" ? (
            <img src={LogoSkyTrust} alt="" />
          ) : data.investment === "stacja_kopernik" ? (
            <img src={LogoStacjaKopernik} alt="" />
          ) : data.investment === "nove_slichowice" ? (
            <img src={LogoNoveSlichowice} alt="" />
          ) : (
            ""
          )}
        </div>
        <div className="apartment-data__list">
          {data?.acfApartment?.buildingNumber && (
            <p>
              {t("Klatka")}:{" "}
              <strong>{data?.acfApartment?.buildingNumber}</strong>
            </p>
          )}
          {data?.investmentBuilding && (
            <p>
              {t("Numer budynku")}: <strong>{data?.investmentBuilding}</strong>
            </p>
          )}
          <p>
            {t("Numer lokalu")}: <strong>{data?.apartmentNumber}</strong>
          </p>
          <p>
            {t("Metraż")} (m<sup>2</sup>): <strong>{data?.area}</strong>
          </p>
          {data?.type !== "local" && data?.roomsnumber && (
            <p>
              {t("Pokoje")}: <strong>{data?.roomsnumber}</strong>
            </p>
          )}
          <p>
            {t("Piętro")}:{" "}
            <strong>
              {data?.floor ? data?.floor : 0}{" "}
              {data?.floorSecond && ` & ${data?.floorSecond}`}
            </strong>
          </p>
          <p>
            {t("Ekspozycja")}: <strong>{t(data?.location)}</strong>
          </p>
          <p>
            {t("Status")}:{" "}
            <strong className={data?.status}>
              {data?.status === "available"
                ? t("Wolne")
                : data?.status === "reserved"
                ? t("Zarezerwowane")
                : t("Sprzedane")}
            </strong>
          </p>
        </div>
      </div>
    </section>
  )
}

export default Data
