import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

import { useLocale } from "context/locale"

const Nav = ({ data, subSubParent }) => {
  const { t } = useLocale()

  const urlCity =
    data?.investment === "Stacja Kopernik" || data?.investment === "Plaza City"
      ? "czestochowa"
      : data?.investment === "Rezydencja Centrum"
      ? "gliwice"
      : data?.investment === "Rezydencja Świerkowa" ||
        data?.investment === "Ultradom"
      ? "radom"
      : "kielce"

  const urlPartial =
    data?.investment === "Sky Trust"
      ? "sky-trust"
      : data?.investment === "Stacja Kopernik"
      ? "stacja-kopernik"
      : data?.investment === "Rezydencja Sikorskiego"
      ? "rezydencja-sikorskiego"
      : data?.investment === "Rezydencja Centrum"
      ? "rezydencja-centrum"
      : data?.investment === "Rezydencja Świerkowa"
      ? "rezydencja-swierkowa"
      : data?.investment === "Krakowska Rogatka"
      ? "krakowska-rogatka"
      : data?.investment === "Plaza City"
      ? "plaza-city"
      : data?.investment === "Ultradom"
      ? "ultradom"
      : "nove-slichowice"

  const investment_type =
    data.type === "offices" ? "biura-i-lokale" : "mieszkania"

  const urlFull = t(`/${investment_type}/${urlCity}/${urlPartial}/`)

  return (
    <section className="apartment-nav">
      <div className="container-fluid">
        <div className="apartment-nav__wrapper">
          <strong>{t(data?.investment)}</strong>
          <nav>
            <Link to={`${urlFull}#apartments`}>
              {data.type === "offices" ? t("Biura") : t("Mieszkania")}
            </Link>
            <Link to={`${urlFull}#gallery`}>{t("Galeria")}</Link>
            <Link to={`${urlFull}#about`}>{t("O inwestycji")}</Link>
            <Link to={`${urlFull}#location`}>{t("Lokalizacja")}</Link>
            <Link to={`${urlFull}#convenients`}>{t("Udogodnienia")}</Link>
            <Link to={`${urlFull}#contact`}>{t("Kontakt")}</Link>
          </nav>
        </div>
      </div>
    </section>
  )
}

export default Nav
