import React, { useState } from "react"
import axios from "axios"
import { navigate } from "gatsby"
import Select from "react-select"

import Input from "components/Input"
import Checkbox from "components/Checkbox"
import Button from "components/Button"

import { useLocale } from "context/locale"

import selectStyles from "utils/selectStyles"

const Form = ({
  formType,
  investment,
  withSelect,
  rmMessage,
  parkingStorage,
}) => {
  const { t } = useLocale()
  const [city, setCity] = useState("")
  const formID =
    formType === "sale" && investment === "Stacja Kopernik"
      ? "3452"
      : formType === "sale" && city === "Radom"
      ? "7469"
      : formType === "sale" && city === "Gliwice"
      ? "7503"
      : formType === "sale"
      ? "756"
      : "755"
  const [send, setSend] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [legalFirst, setLegalFirst] = useState(false)

  const isBrowser = typeof window !== "undefined"
  const location = isBrowser ? window.location.href : null

  const formSubmit = e => {
    e.preventDefault()
    setSend(true)

    let formData = new FormData()

    formData.set("investment", investment ? investment : "")
    formData.set("firstName", name)
    formData.set("email", email)
    formData.set("phone", phone)
    formData.set("message", message)
    formData.set("city", city)
    formData.set("parkingStorage", parkingStorage)
    formData.set("legalFirst", legalFirst)
    formData.set("pageurl", location)

    axios({
      method: "post",
      url: `https://trust-investment.zenx.pl/wp-json/contact-form-7/v1/contact-forms/${formID}/feedback/`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(() => {
        isBrowser &&
          window.Monitor.TrackForm("trustForm", [
            { "yl-investment": investment },
            { "yl-name": name },
            { "yl-email": email },
            { "yl-phone": phone },
            { "yl-message": message },
            { "yl-city": city },
            { "yl-site_url": location },
            { "yl-legal-main": legalFirst },
          ])

        console.log("Submit success")
        navigate(t("/kontakt-potwierdzenie/"))
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <form onSubmit={formSubmit} id="trustForm">
      {withSelect && (
        <div className="select-wrapper">
          <Select
            placeholder={t("wybierz miasto*")}
            styles={selectStyles}
            defaultValue={""}
            options={[
              { value: "Kielce", label: t("Kielce") },
              { value: "Częstochowa", label: t("Częstochowa") },
              { value: "Radom", label: t("Radom") },
              { value: "Gliwice", label: t("Gliwice") },
            ]}
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            onChange={e => setCity(e.value)}
          />
        </div>
      )}
      <Input
        placeholder={`${t("imię i nazwisko")}*`}
        type="text"
        id="name"
        name="name"
        value={name}
        onChange={e => setName(e.target.value)}
        required
      />
      <Input
        placeholder={`${t("telefon")}*`}
        type="phone"
        id="phone"
        name="phone"
        value={phone}
        onChange={e => setPhone(e.target.value)}
        required
      />
      <Input
        placeholder="e-mail*"
        type="email"
        id="email"
        name="email"
        value={email}
        onChange={e => setEmail(e.target.value)}
        required
      />
      {!rmMessage && (
        <Input
          placeholder={
            investment
              ? t("proszę o kontakt w sprawie inwestycji")
              : t("treść wiadomości")
          }
          type="textarea"
          id="message"
          name="message"
          value={message}
          onChange={e => setMessage(e.target.value)}
          rows="9"
        />
      )}
      <div className="contact-form__action">
        <Checkbox
          name="legalFirst"
          checked={legalFirst}
          onChange={() => setLegalFirst(!legalFirst)}
          required
        >
          {t(
            "Wyrażam dobrowolną zgodę na przetwarzanie moich danych osobowych przez TRUST INVESTMENT SPÓŁKA AKCYJNA, ul. Robotnicza 1, 25-662 Kielce NIP: 6631877218, REGON: 381244959, KRS: 0000747790 (Administrator danych), w celu marketingu bezpośredniego dotyczącego własnych produktów i usług. Obowiązek informacyjny zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych osobowych (RODO) z dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z 04.05.2016): informacje o przetwarzaniu przez nas danych, jak również o prawach osób, których dane dotyczą znajdują się w naszej polityce prywatności."
          )}
        </Checkbox>
        <Button
          className="button button--circle"
          type="submit"
          disabled={!legalFirst}
        >
          {send === true ? `${t("Wysyłanie")}...` : t("Wyślij")}
        </Button>
      </div>
    </form>
  )
}

export default Form
