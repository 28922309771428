import "./styles.scss"

import React, { useState } from "react"
import scrollToElement from "scroll-to-element"

import FavoriteCompare from "components/FavoriteCompare"

import { useLocale } from "context/locale"

const Content = ({ id, data, investmentRaw }) => {
  const isBrowser = typeof window !== "undefined"
  const { t } = useLocale()
  const [view, setView] = useState(1)

  return (
    <section className="apartment-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            <div className="apartment-content__table">
              <div className="apartment-content__table-header">
                <div className="row">
                  <div className="col">{t("Numer")}</div>
                  <div className="col-6">{t("Pomieszczenie")}</div>
                  <div className="col-3">
                    {t("Metraż")} (m<sup>2</sup>)
                  </div>
                </div>
              </div>
              <div className="apartment-content__table-content">
                {data?.roomsList?.map((item, index) => (
                  <div className="row" key={index}>
                    <div className="col">{!item.hideLabel && index + 1}</div>
                    <div className="col-6">{t(item.name)}</div>
                    <div className="col-3">{item.area}</div>
                  </div>
                ))}
              </div>
              <div className="row">
                <div className="col" />
                <div className="col-6">
                  <strong>{t("Suma")}</strong>
                </div>
                <div className="col-3">
                  <strong>{data?.area}</strong>
                </div>
              </div>
              {data?.placesList && (
                <div className="apartment-content__table-list">
                  {data?.placesList?.map((item, index) => (
                    <div className="row" key={index}>
                      <div className="col"></div>
                      <div className="col-6">{t(item.name)}</div>
                      <div className="col-3">{item.area}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <button
              className="button button--block"
              onClick={() => {
                scrollToElement("#contact")
                isBrowser &&
                  window?.ylData.push({
                    event: { eventName: `${investmentRaw}_ask_apartment` },
                  })
              }}
            >
              {data?.type === "local"
                ? t("zapytaj o ten lokal")
                : t("zapytaj o to mieszkanie")}
            </button>
            <br />
            {data?.pdf?.localFile?.publicURL && (
              <a
                className="button button--bordered button--block"
                href={data?.pdf?.localFile?.publicURL}
                target="_blank"
                rel="noreferrer"
              >
                {data?.type === "local"
                  ? t("karta lokalu")
                  : t("karta mieszkania")}
              </a>
            )}
            <div className="apartment-content__info">
              <h4>
                {data?.type === "local"
                  ? t("Zainteresował Cię ten lokal?")
                  : t("Zainteresowało Cię to mieszkanie?")}
              </h4>
              <p>{t("Dodaj je do schowka lub porównania")}</p>
              <FavoriteCompare id={id} />
            </div>
          </div>

          <div className="col-md-7 offset-md-1">
            <div className="apartment-content__nav">
              {data?.visualization2d?.sourceUrl && (
                <button
                  className={view === 1 ? "current" : ""}
                  onClick={() => setView(1)}
                >
                  {t("Rzut")} 2D
                </button>
              )}
              {data?.visualization3d?.sourceUrl && (
                <button
                  className={view === 2 ? "current" : ""}
                  onClick={() => setView(2)}
                >
                  {t("Rzut")} 3D
                </button>
              )}
              {data?.floorView?.sourceUrl && (
                <button
                  className={view === 3 ? "current" : ""}
                  onClick={() => setView(3)}
                >
                  {t("Kondygnacja")}
                </button>
              )}
            </div>

            <div className="apartment-content__view">
              {view === 1 && (
                <img src={data?.visualization2d?.sourceUrl} alt="" />
              )}
              {view === 2 && (
                <img src={data?.visualization3d?.sourceUrl} alt="" />
              )}
              {view === 3 && <img src={data?.floorView?.sourceUrl} alt="" />}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Content
