/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react"
import { useQueryParam, NumberParam, StringParam } from "use-query-params"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactForm from "components/ContactForm"

import { Nav, Data, Content } from "page_components/apartment"

const Apartment = ({ pageContext, location }) => {
  const isBrowser = typeof window !== "undefined"
  const [floorParam] = useQueryParam("floor", NumberParam)
  const [viewParam] = useQueryParam("view", NumberParam)
  const [buildingParam] = useQueryParam("building", StringParam)

  const data =
    pageContext?.data?.data?.acfApartment || pageContext?.data?.data?.acfOffice

  useEffect(() => {
    isBrowser &&
      window?.ylData.push({
        product: { products: [`${pageContext?.data?.data?.id}`] },
      })
  }, [])

  return (
    <Layout
      location={location}
      seo={{ title: pageContext?.data?.title }}
      rmGlobalPopup
    >
      <Breadcrumbs
        parent={{
          label: pageContext?.data?.investment,
          url: pageContext?.data?.investmentRaw.replace("_", "-"),
        }}
        subParent={
          pageContext?.data?.investment === "Ultradom"
            ? { label: "Radom", url: "/mieszkania/radom" }
            : pageContext?.data?.investment === "Stacja Kopernik"
            ? { label: "Częstochowa", url: "/mieszkania/czestochowa" }
            : pageContext?.data?.investment === "Panorama Struga"
            ? { label: "Radom", url: "/mieszkania/radom" }
            : pageContext?.data?.investment === "Rezydencja Świerkowa"
            ? { label: "Radom", url: "/mieszkania/radom" }
            : pageContext?.data?.investment === "Rezydencja Centrum"
            ? { label: "Gliwice", url: "/mieszkania/gliwice" }
            : pageContext?.data?.investment === "Sky Trust"
            ? {
                label: "Kielce",
                url: `/${
                  pageContext?.data?.type === "offices"
                    ? "biura-i-lokale"
                    : "mieszkania"
                }/kielce`,
              }
            : pageContext?.data?.investment === "Plaza City"
            ? {
                label: "Częstochowa",
                url: "/mieszkania/czestochowa",
              }
            : { label: "Kielce", url: "/mieszkania/kielce" }
        }
        subSubParent={
          pageContext?.data?.type === "offices"
            ? { label: "Biura i lokale", url: "/biura-i-lokale" }
            : { label: "Mieszkania", url: "/mieszkania" }
        }
        title={data?.title || pageContext?.data?.title}
        floorParam={floorParam}
        viewParam={viewParam}
        buildingParam={buildingParam}
        withBack
      />
      <Nav data={pageContext.data} type={pageContext?.data?.type} />
      <Data data={data} title={data?.title || pageContext?.data?.title} />
      <Content
        id={pageContext?.data?.data?.id}
        data={data}
        investmentRaw={pageContext?.data?.investmentRaw}
      />
      <ContactForm
        formType="sale"
        investment={pageContext?.data?.investment}
        investmentRaw={data?.investment}
      />
    </Layout>
  )
}

export default Apartment
